<template>
  <div>

    <div class="d-flex justify-content-center">
      <b-spinner
        v-show="landingPagesDataState"
        label="Loading..."
        :variant="'primary'"
      />
      <b-alert
        v-show="!landingPagesDataState && landingPages.length == 0"
        variant="secondary"
        show
      >
        <div class="alert-body">
          <span>No data Landing Page existed.</span>
        </div>
      </b-alert>
    </div>

    <b-row class="mt-0 pt-0 match-height d-flex justify-content-start align-items-center">
      <b-col
        v-for="(item, index) in landingPages"
        :key="index"
        md="6"
        lg="4"
        xl="3"
      >
        <b-card
          class="mt-0 pt-0 d-flex justify-content-center text-center"
          style="height: 340px; max-height: 100%;"
        >
          <b-card-body class="p-0">
            <div
              style="height: 60px; max-height: 100%;"
              class="my-1"
            >
              <div
                class="font-small-2"
                style="height: 17px; max-height: 100%;"
              >
                <div v-if="item.salesperson_lists_count <= 0">
                  <feather-icon
                    icon="AlertTriangleIcon"
                  />
                  Add salesperson to receive leads
                </div>
              </div>
              <div class="pb-1">
                <h3>{{ item.title | truncate(20, '...') }}</h3>
              </div>
            </div>
            <hr class="mb-2">

            <!-- follower projects rank -->
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Conv. Rate
                </h6>
                <h4
                  v-if="item.visitor == 0"
                  class="mb-0"
                >
                  N/A
                </h4>
                <h4
                  v-else
                  class="mb-0"
                >
                  {{ (item.form_inputs_count / item.visitor)*100 | formatNumber }}%
                </h4>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Subm. Goal
                </h6>
                <h4
                  v-if="item.form_inputs_count === null || item.submission_goal === null"
                  class="mb-0"
                >
                  N/A
                </h4>
                <h4
                  v-else
                  class="mb-0"
                >
                  {{ item.form_inputs_count===null ? 0 : item.form_inputs_count }} / {{ item.submission_goal===null ? 0 : item.submission_goal }}
                </h4>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">
                  Sales Goal
                </h6>
                <h4
                  v-if="item.sales_goal_deal === null"
                  class="mb-0"
                >
                  N/A
                </h4>
                <span
                  v-else
                  class="mb-0"
                >
                  <b-button
                    v-if="item.sales_goal_deal === 0 || item.sales_goal_deal === '0'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.bottom="formatCurrencyString(item.total_deal_price) +' / '+ formatCurrencyString(item.sales_goal_deal)"
                    variant="flat-primary"
                    size="lg"
                    class="p-0"
                  >
                    N/A
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.bottom="formatCurrencyString(item.total_deal_price) +' / '+ formatCurrencyString(item.sales_goal_deal)"
                    variant="flat-primary"
                    size="lg"
                    class="p-0"
                  >
                    {{ (item.total_deal_price / item.sales_goal_deal)*100 | formatNumber }}%
                  </b-button>
                </span>
              </div>
            </div>
            <div class="py-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                pill
                :to="{ name: 'leads-landing-page', params: { uuid: item.uuid } }"
              >
                View Lead
              </b-button>
            </div>
          </b-card-body>

          <b-card-footer class="mt-1 px-0">
            <div class="d-flex justify-content-between">
              <div>
                <small class="text-muted">
                  <b-link
                    :to="{ name: 'form-managers-display', params: { id: item.form_id } }"
                    target="_blank"
                  >
                    <span v-if="item.form_inputs_count != 0">Form</span>
                  </b-link>
                </small>
              </div>
              <div>
                <small class="text-muted">{{ item.form_inputs_count }} entries</small>
              </div>
            </div>
          </b-card-footer>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// import Vue from 'vue'
import {
  BCard, BCardBody, BCardFooter, BRow, BCol, BButton, BSpinner, BLink, BAlert, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

const numeral = require('numeral')

export default {
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BLink,
    BAlert,

    // vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) { return text.substring(0, length) + suffix }
      return text
    },
    formatNumber(value) {
      return numeral(value).format('0.00')
    },
  },
  data() {
    return {
      landingPagesDataState: true,
      landingPages: [],
    }
  },
  created() {
    this.fetchLandingPages()
  },
  methods: {
    async fetchLandingPages() {
      if (this.$store.state.currentUser === null){
        await store.dispatch('app-user/getSelf')
          .then(response => {
            this.$store.state.currentUser = response.data
            store.dispatch('menu-lead/getAllLandingPages', {
              user_id: this.$store.state.currentUser.id,
            })
              .then(res => {
                this.landingPages = res.data
                this.landingPagesDataState = false
              })
          })
      } else {
        await store.dispatch('menu-lead/getAllLandingPages', {
          user_id: this.$store.state.currentUser.id,
        })
          .then(response => {
            this.landingPages = response.data
            this.landingPagesDataState = false
          })
      }
    },
    formatCurrencyString(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
